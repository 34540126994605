import * as React from 'react';

import {
  Text,
  BlogImage,
  BlogInfographics,
  BlogLiterature,
  BlogLink,
} from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import infographic from '../../../images/educational-corner/blog4/infografika.png';
import articulators from '../../../images/educational-corner/blog4/Artikulatori.png';
import phonemicHearing from '../../../images/educational-corner/blog4/Fonematski-sluh.png';

const MotorAndHearingPredictions = () => {
  return (
    <PageNew
      title="KOKOLINGO - Motoričke i slušne predvježbe"
      description="Budući da su motoričke i slušne predvježbe temelj pravilnog izgovora,
      postavljene su na početnoj razini Kokolingo digitalne vježbenice."
      headline="Motoričke i slušne predvježbe - preduvjeti za pravilan izgovor glasova"
    >
      <Text as="p">
        Govor je osnovno sredstvo komunikacije u svakodnevnom životu ljudi.
        Djeca su govorom okružena čak i prije samog rođenja. Usvaja se spontano,
        u interakcijama s drugim ljudima te je vrlo složena vještina.
        <b>
          {' '}
          Osnovu govora čini niz glasova koji se povezuju u riječi i rečenice
        </b>
        . Kako bi nas nepoznata osoba razumjela, potrebno je pravilno izgovarati
        sve glasove hrvatskog jezika. Ako dijete ima poremećen izgovor jednog
        ili više glasova, može doći do teškoća u komunikaciji sa sugovornikom.
        <b>
          {' '}
          U pozadini artikulacijskih poremećaja može stajati nerazvijen
          fonematski sluh ili odstupanja u pokretljivosti i građi artikulatora
        </b>
        .
      </Text>
      <Text as="h2">Fonematski sluh</Text>
      <Text as="p">
        <b>
          {' '}
          Fonematski sluh omogućava prepoznavanje glasova unutar riječi, kao i
          razlikovanje međusobno sličnih glasova
        </b>
        , npr. glasova “Š” i “Ž”. Ovo je vrlo važna vještina kojom dijete treba
        ovladati jer s njezinim razvojem počinje usklađivati vlastiti izgovor s
        izgovorom glasova koje čuje u okolini. Kada dijete počne slušno uočavati
        razlike između glasova, tada će mnogo lakše dobiti njihov pravilan
        izgovor.
      </Text>
      <BlogImage
        imgSrc={phonemicHearing}
        imgAlt="Fonematski sluh kod djece"
        imgTitle="Fonematski sluh"
      />
      <Text as="h2">Artikulatori</Text>
      <Text as="p">
        <b>
          Artikulatori su govorni organi koji mogu biti pomični ili nepomični
        </b>
        . U pomične govorne organe ubrajamo donju čeljust, usne i jezik, a u
        nepomične gornju čeljust i zube. Da bi dijete pravilno proizvelo
        određeni glas, mora biti artikulacijski spretno, odnosno mora znati
        pomicati artikulatore na odgovarajući način. Ponekad može doći do
        odstupanja u građi i pokretljivosti artikulatora zbog organskih
        odstupanja, kao što je npr. prekratka podjezična vezica koja onemogućava
        urednu pokretljivost jezika.
      </Text>
      <BlogImage
        imgSrc={articulators}
        imgAlt="Artikulatori kod djece"
        imgTitle="Artikulatori"
      />
      <Text as="p">
        Ako dijete ne razlikuje međusobno slične glasove ili ne može proizvesti
        pravilan pokret artikulatora za određeni glas, tada treba započeti s
        motoričkim i slušnim predvježbama. One su temelj pravilnog izgovora.
      </Text>
      <Text as="h2">Slušne predvježbe</Text>
      <Text as="p">
        <b>
          Slušne predvježbe odnose se na vježbe za razvijanje fonematskog sluha
        </b>
        , odnosno razvijanje sposobnosti razlikovanja međusobno bliskih glasova,
        npr. glasova “K” i “G”. Najčešće se u vježbama za razvoj fonematskog
        sluha upotrebljavaju riječi, no u prvoj fazi vježbi mogu se koristiti i
        negovorni zvukovi. Ovi zadatci mogu biti u obliku prepoznavanja glazbala
        prema njihovu zvuku ili prepoznavanja životinja prema njihovu glasanju.
        Zadaci s govornim zvukovima mogu biti u obliku razlikovanja riječi koje
        se razlikuju u samo jednom glasu, npr. “kosa” i “koza” ili u smišljanju
        riječi koje počinju određenim glasom.
        <b>
          {' '}
          Cilj je i jednih i drugih tipova zadataka poticati razvoj slušne
          pažnje i slušnog pamćenja
        </b>
        .
      </Text>
      <Text as="h2">Motoričke predvježbe</Text>
      <Text as="p">
        <b>
          Motoričke predvježbe odnose se na vježbe jačanja muskulature i
          pokretljivosti artikulacijskih organa - jezika, donje čeljusti, usana
        </b>
        . Cilj je ovih vježbi dobivanje pravilnih pokreta za izgovor određenoga
        glasa. One se provode tako da se prvo vježbaju jednostavniji pokreti,
        koji se postupno stapaju u složenije. Provode se dvije vrste vježbi;
        vježbe zadržavanja artikulatora u određenoj poziciji i vježbe
        pokretljivosti artikulatora. Ovim vježbama
        <b>
          {' '}
          dijete uči proizvesti pokrete koji su kontrolirani, usklađeni i
          precizni
        </b>
        .
      </Text>
      <Text as="p">
        Kada dijete svlada motoričke i slušne predvježbe, može krenuti s ostalim
        vježbama za dobivanje pravilnog izgovora glasova.
      </Text>
      <Text as="p">
        Budući da su motoričke i slušne predvježbe temelj pravilnog izgovora,
        postavljene su na početnoj razini
        <BlogLink> Kokolingo digitalne vježbenice.</BlogLink>
      </Text>
      <Text as="p">Krešimir Perša, mag. logoped.</Text>
      <BlogLiterature>
        <ul>
          <li>
            Posokhova, I. (2001).
            <i>Dislalija - Upute za sustavnu terapiju - logopedski priručnik</i>
            . Lekenik: Ostvarenje.
          </li>
        </ul>
      </BlogLiterature>
      <BlogInfographics infographicSrc={infographic} />
      <SectionBlog />
    </PageNew>
  );
};

export default MotorAndHearingPredictions;
